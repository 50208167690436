import moment from "moment";

 const changeStoppedDayUpdate =  (countClickPrevNext: number, refCalendarHyundaiProgramacao: any, functionUpdate ) => {
     try {
        if(refCalendarHyundaiProgramacao){
            const dateFilter = moment(refCalendarHyundaiProgramacao.getApi()?.getDate() ?? undefined);
            const isSameAsToday = dateFilter.isSame(moment(), 'day');
            
            if (!isSameAsToday && countClickPrevNext == 0) {
                 console.log('mudou')
                refCalendarHyundaiProgramacao.getApi().today();
            }
        }     
    } catch (error) {
        console.log(error)
    }      
}

export { changeStoppedDayUpdate}
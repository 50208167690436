let scrollTimeouts: number[] = [];

function clearScrollTimeouts(): void {
    scrollTimeouts.forEach(timeoutId => clearTimeout(timeoutId));
    scrollTimeouts = []; 
}

const statusFullScreen = (div: string): void => {
    const header = document.querySelector('.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr');
    const title = document.querySelector('#title-agenda') as HTMLElement;
    const bodyAgenda = document.querySelector('.fc-view-harness.fc-view-harness-passive') as HTMLElement;
    const timer = document.querySelector('#timer-agenda') as HTMLElement;
    const logo = document.querySelector('#logo-agenda');
    const buttonFullscreen = document.querySelector('#fullScreen-agenda') as HTMLElement
    const elem = document.querySelector(div);

    const exitFullScreen = () => {
        if (header && title && bodyAgenda && timer && buttonFullscreen) {
            header.classList.remove('fullscreen-servicos');
            title.style.top = '25px';
            bodyAgenda.style.marginTop = '0px';
            timer.style.marginRight = '0px';
            buttonFullscreen.style.position = 'absolute'
            buttonFullscreen.style.zIndex = '0'
        }
    };

    const enterFullScreen = () => {
        if (header && title && bodyAgenda && timer && logo && elem && buttonFullscreen) {
            header.appendChild(logo);
            header.appendChild(timer);
            header.appendChild(title);
            header.classList.add('fullscreen-servicos');
            title.style.top = '37px';
            bodyAgenda.style.marginTop = '61px';
            timer.style.marginRight = '28px';
            buttonFullscreen.style.position = 'fixed'
            buttonFullscreen.style.zIndex = '100'

            if (hasVerticalScroll(elem)) {
                toggleScroll(elem);
            }
        }
    };

    document.addEventListener("fullscreenchange", () => {
        if (!document.fullscreenElement) {
            exitFullScreen();
        } else if (document.fullscreenElement === elem) {
            clearScrollTimeouts()
            enterFullScreen();
        }
    });
};

// Função de exemplo para detectar scroll vertical (deve ser definida conforme sua lógica)
const hasVerticalScroll = (element: Element | null): boolean => {
    return element ? element.scrollHeight > element.clientHeight : false;
};

function toggleScroll(elem: Element): void {
    const scrollStep = elem.clientHeight / 3;
    const timeScrollStep = 10000;

    function scrollToBottom(): void {
        const distanceToBottom = elem.scrollHeight - elem.scrollTop - elem.clientHeight;
        const stepsToBottom = Math.ceil(distanceToBottom / scrollStep);
        let i = 0;

        function step() {
            if (i < stepsToBottom) {
                elem.scrollBy({ top: scrollStep, behavior: "smooth" });
                i++;
                scrollTimeouts.push(setTimeout(step, timeScrollStep));
            } else {
                elem.scrollTo({ top: elem.scrollHeight - elem.clientHeight });
                setTimeout(scrollToTop, 1000); // Tempo reduzido para a transição
            }
        }
        step();
    }

    function scrollToTop(): void {
        const distanceToTop = elem.scrollTop;
        const stepsToTop = Math.ceil(distanceToTop / scrollStep);
        let i = 0;

        function step() {
            if (i < stepsToTop) {
                elem.scrollBy({ top: -scrollStep, behavior: "smooth" });
                i++;
                scrollTimeouts.push(setTimeout(step, timeScrollStep));
            } else {
                elem.scrollTo({ top: 0 });
                setTimeout(scrollToBottom, 1000); // Tempo reduzido para a transição
            }
        }
        step();
    }

    clearScrollTimeouts();
    scrollToBottom();
}

const frontDeskFullScreen = (div: string): void => {

    const elem = document.querySelector(div);

    const exitFullScreen = () => {
    };

    const enterFullScreen = () => {
        if (elem) {
            toggleScroll(elem);
        }
    };

    document.addEventListener("fullscreenchange", () => {
        if (!document.fullscreenElement) {
            exitFullScreen();
        } else if (document.fullscreenElement === elem) {
            clearScrollTimeouts()
            enterFullScreen();
        }
    });
};

export { statusFullScreen, frontDeskFullScreen }